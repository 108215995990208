import request from '@/utils/request';

// 获取讲师目录
export const lecturerFolder = params => request.get(`study/lecturer/folder/tree`, params)

// 获取讲师列表
export const lecturerList = params => request.post(`study/lecturer/list`, params)

// 获取讲师等级列表
export const lecturerLevel = params => request.get(`study/lecturer/level`, params)

// 获取讲师详情
export const lecturerDetail = id => request.get(`study/lecturer/${id}/detail`)

// 获取讲师在线课程
export const lecturerCourse = params => request.post(`study/lecturer/${params.id}/course`, params)

// 获取讲师线下培训
export const lecturerLocalCourse = params => request.post(`study/lecturer/${params.id}/train`, params)

// 获取讲师荣耀
export const lecturerHonor = params => request.post(`study/lecturer/${params.id}/honor`, params)
